<template>
  <Container>
    <TrailerCard />
  </Container>

  <Container extend-down>
    <template #background>
      <div
        class="bg-white w-full h-full"
      />
    </template>
    <h2
      class="text-center text-slate-800 my-10"
    >
      {{ t('jc.view.index.news.title') }}
    </h2>
    <div
      class="flex flex-col laptop:flex-row gap-8"
    >
      <template
        v-for="news in newsShowcase"
        :key="news.id"
      >
        <template
          v-if="news"
        >
          <news-card
            :news="news"
            class="flex-1"
          />
        </template>
      </template>
    </div>
    <NuxtLink
      :to="localePath({
        name: 'news',
      })"
      class="bg-secondary hover:bg-secondary-highlighted active:bg-secondary-selected text-white
              text-center rounded py-2.5 px-8 mx-auto laptop:ml-auto my-auto rounded-full text-[1.2em] font-semibold"
    >
      <font-awesome-icon
        icon="newspaper"
        class="mr-3"
      />
      <span>{{ t('jc.view.index.news.button.label') }}</span>
    </NuxtLink>
  </Container>

  <Container extend-up>
    <template #background>
      <NuxtPicture
        src="images/nature/hill.png"
        :title="t('jc.view.index.social.background.title')"
        :alt="t('jc.view.index.social.background.alt')"
        class="bg-image bg-white w-full h-full"
      />
    </template>
    <h2
      class="text-center text-white h1"
    >
      {{ t('jc.view.index.social.title') }}
    </h2>
    <p
      class="text-center text-white tablet:w-2/3 mx-auto"
    >
      {{ t('jc.view.index.social.text') }}
    </p>
    <SocialCards />
  </Container>

  <Container>
    <ContentCard
      :heading="t('jc.view.team.ourGoal.title')"
      :paragraph="t('jc.view.team.ourGoal.text')"
    >
      <IconSection
        :sections="ourGoalSections"
      />
    </ContentCard>
  </Container>

  <Container>
    <ImageCard
      :heading="t('jc.view.index.participate.title')"
      :paragraph="t('jc.view.index.participate.text')"
      :image="{ src: 'images/view/index/call_to_action_background.png' }"
    >
      <NuxtLink
        :to="localePath({ name: 'participate' })"
        class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
              text-center rounded py-2 px-4 mt-10 mx-auto font-semibold"
      >
        <font-awesome-icon
          icon="user-plus"
          class="mr-2"
        />
        <span>{{ t('jc.view.index.participate.button.label') }}</span>
      </NuxtLink>
    </ImageCard>
  </Container>
</template>

<script setup lang="ts">
import { parseNews, type StringifiedNews } from "~/utils/charon-types";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const { t } = useI18n()
const config = useRuntimeConfig()
const charon = useCharon()
const localePath = useLocalePath()

definePageMeta({
  nameKey: 'index',
  layout: 'home'
})

const { data: newsShowcase } = useAsyncData(
  () => charon<StringifiedNews[]>(`/news`, {
    params: {
      size: 3,
      sort: ['publishDate[dec]']
    },
  }),
  {
    lazy: true,
    default: () => [],
    transform: raws => raws.map(it => parseNews(it))
  }
)

const ourGoalSections = [
  {
    icon: "gamepad-modern",
    title: t("jc.view.team.ourGoal.section.game.title"),
    text: t("jc.view.team.ourGoal.section.game.text")
  },
  {
    icon: "hands-holding-heart",
    title: t("jc.view.team.ourGoal.section.community.title"),
    text: t("jc.view.team.ourGoal.section.community.text")
  },
  {
    icon: "star-shooting",
    title: t("jc.view.team.ourGoal.section.impact.title"),
    text: t("jc.view.team.ourGoal.section.impact.text")
  },
  {
    icon: "money-bills",
    title: t("jc.view.team.ourGoal.section.finance.title"),
    text: t("jc.view.team.ourGoal.section.finance.text")
  },
  {
    icon: "user-graduate",
    title: t("jc.view.team.ourGoal.section.experience.title"),
    text: t("jc.view.team.ourGoal.section.experience.text")
  },
]
</script>

<style scoped lang="postcss">
:deep(.bg-image img) {
  @apply w-full h-full object-cover object-top
}
</style>
